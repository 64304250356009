
.page {
  width: auto;
  height: 100vh;
  overflow: hidden
}

.webBg {
  filter: brightness(80%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 35%;
}

.content {
  z-index: 300;
  position: absolute;
  top: 5vh;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;

  display: flex;
	flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1024px)  {
    top: 7vh;
  } 

  @media only screen and (min-width: 540px) and (max-width: 1024px) {
    top: 4vh;
  }

}

.logo{
  height: 13vh;
  width: auto;
  margin-bottom: 3vh;

  @media screen and (max-width: 1024px)  {
    margin-bottom: 7vh;
  } 

  @media only screen and (min-width: 540px) and (max-width: 1024px) {
    margin-bottom: 6vh;
  }

  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

}

.title {
  font-family: "opake", sans-serif;
  font-weight: 900;
  font-style: normal;

  text-align: center;
  color: #fde9cc ;
  font-size: 4vh;

  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  @media screen and (max-width: 1024px)  {
    font-size: 2.9vh;
  } 
}

.links {

  margin-top: 1.5vh;

  display: flex;
	align-items: center;


  @media screen and (max-width: 1024px)  {

    margin-right: 0vh;
    margin-left: 0vh;

    display: flex;
	  align-items: center;
    flex-direction: column;
  } 


  &-buttons {
    
  font-family: 'Raleway', sans-serif;
  font-size: 2vh;
  color: white ;

  margin-right: 3vh;
  margin-left: 3vh;
  padding: 9px;
   
  display: flex;
  align-items: center;

  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);

  @media screen and (max-width: 1024px)  {

    margin-right: 0vh;
    margin-top: 1vh;
    padding: 13px;
  } 

  @media only screen and (min-width: 540px) and (max-width: 1024px) {
  padding: 17px;
  }
  
  &:first-child{
    &:hover{
      cursor: pointer;
      background: rgba(255, 255, 255, 0.299);
    }
  }
  }

  &-icon{
    margin-right: 6px;
  }
}

.contact{

  font-family: 'Raleway', sans-serif;
  font-size: 2vh;
  color: white ;

  margin-top: 64vh;

  @media screen and (max-width: 460px)  {
    margin-top:10vh ;
    font-size: 1.6vh;
  } 

  @media only screen and (min-width: 540px) and (max-width: 1024px) {
    margin-top:50vh ;
    font-size: 2.3vh;
  }

}